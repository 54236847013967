import { render, staticRenderFns } from "./tab.vue?vue&type=template&id=27f0d6de&scoped=true"
import script from "./tab.vue?vue&type=script&lang=js"
export * from "./tab.vue?vue&type=script&lang=js"
import style0 from "./tab.vue?vue&type=style&index=0&id=27f0d6de&prod&rel=stylesheet%2Fscss&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "27f0d6de",
  null
  
)

export default component.exports